<template>
  <div>
    <div class="container" v-if="LoadPage">
      <div v-if="this.task && !singleApprovalSuccess">
        <Breadcrumb class="bg-transparent" :breadcrumbList="[
          { 'name': 'Admin Dashboard', 'link': $router.resolve({ name: 'admin' }).href },
          { 'name': 'Tasks', 'link': $router.resolve({ name: 'admin_task' }).href },
          { 'name': task.slug + ' / ' + task.user.username }
        ]"/>
        <div class="main-container">
          <h2>Tasks Pending Review</h2>
          <b-alert v-if="task.decisions[0] !== undefined" show variant="warning" class="mt-3 mb-0 w-100 px-0 p-3">
            <span class="alert mb-0 pb-0 mt-1">
             {{ $t('last_review_decision') }} {{ task.decisions[0].reason }}
           </span>
          </b-alert>
          <div class="p-0 mt-2">
            <div class="p-3" :id="task.hash">
              <div class="row">
                <div class="col-12 col-md-8 pb-3 content-box pt-3 d-flex flex-col align-items-center">
                  <h3 class="font-weight-bold">{{ task.title }}</h3>
                  <p class="text-left text-secondary">
                    <small>
                      <strong>Total assets</strong>: {{ getImagesFromAssets(task).length }}
                    </small>
                   </p>

                  <div class="w-75 pb-4 mb-3">
                    <Carousel :images="getImagesFromAssets(task)" :indicators="true" :taskHash="task.hash"/>
                  </div>

                  <h4 class="text-left mt-0 mb-3"><strong>{{ $t('task_description') }}</strong></h4>
                  <p class=" text-left" v-html="this.$sanitize(task.description)"/>

                  <h4 class="text-left mt-0 mb-3"><strong>{{ $t('task_package_description') }}</strong></h4>
                  <p class="text-left mb-4" v-html="this.$sanitize(task.packages[0].description)"/>

                  <div class="d-flex w-100 mb-3">
                    <div class="w-50 d-flex p-3 flex-col align-items-center border-right border-secondary">
                      <h5 class="text-left mt-0 mb-1"><strong>{{ $t('task_package_price') }}</strong></h5>
                      <p class=" text-left">${{ task.packages[0].price }}</p>
                      <h5 class="text-left mt-0 mb-1"><strong>{{ $t('date_created') }}</strong></h5>
                      <p class=" text-left">{{ datetime(task.date_created) }}</p>
                      <h5 class="text-left mt-0 mb-1"><strong>{{ $t('task_advice_title_slug') }}</strong></h5>
                      <p class=" text-left">https://anytask.com/{{ task.user.username }}/{{ task.slug }}</p>
                    </div>
                    <div class="w-50 d-flex p-3 flex-col align-items-center">
                      <h5 class="text-left mt-0 mb-1"><strong>{{ $t('categories') }}:</strong></h5>
                      <ul class="text-left categories-list pl-0">
                        <li class="uppercase text-center" v-for="(category,index) in task.categories" v-bind:key="index">
                          <small>
                            {{ category.parent_category.name + '  >  ' + category.name}}
                          </small>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="cols-12 col-lg-4 pb-3 pr-0">
                  <div class="content-box p-3 mb-3">
                    <p class="text-left text-secondary mb-0">
                      <small>
                        <strong>Username:</strong> {{ task.user.username }}
                        <br/>
                        <strong>Verification:</strong> {{ task.user.verification_type }}
                        <br/>
                        <strong>SSO ID:</strong> {{ task.user.electroneum_id }}
                      </small>
                    </p>
                  </div>
                  <div class="content-box p-3 mb-3">
                    <div>
                      <h5 class="text-left font-weight-bold mb-2">{{ $t('accept_task') }}</h5>
                      <b-button :id="'btn-accept-' + task.hash" v-on:click="decision(task.hash, $t('accepted'), 1)" class="w-100" variant="success">
                        {{ $t('accept_button_review') }}
                      </b-button>
                    </div>
                    <hr/>
                    <div>
                      <h5 class="text-left font-weight-bold mb-2">{{ $t('decline_task') }}</h5>
                      <p class="text-left mb-0">Please enter a reason why it should not go live</p>
                      <div class="text-center alert alert-warning py-2 px-3 mt-2 mb-3">
                        <small>
                          This text will be sent to the user. Please be brief and concise.
                        </small>
                      </div>
                      <div :id="'taskWarning-' + task.hash" class="p-1 alert alert-danger text-left mb-3 p-2" style="display:none;">
                        <i class="fa-solid fa-triangle-exclamation"></i>
                        {{ $t('decision_missing_reason') }}
                      </div>
                      <div :id="'taskWarningApi-' + task.hash" class="p-1 alert alert-danger text-left m-0" style="display:none;">
                        <ul>
                          <li v-for="(error, index) in errorMessagesApi" v-bind:key="index">{{ error }}</li>
                        </ul>
                      </div>
                      <div>
                        <select class="mb-2 custom-select" @change="updateDeclineMessage($event.target.value)">
                          <option value="">Select a declined response...</option>
                          <option v-for="(value, index) in decline_messages" :key="index" :value="value.text">{{ value.title }}</option>
                        </select>
                        <textarea :id="'taskDecision' + task.hash" :placeholder="$t('decision_reason_heading')" class="form-control mb-3" name="textarea" rows="3"/>
                        <div class="align-center">
                          <b-button :id="'btn-decline-' + task.hash" v-on:click="decision(task.hash, $t('declined_button'), 0)" class="w-100" variant="danger">
                            {{ $t('submit_reason_decline') }}
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="content-box p-3 mb-3">
                    <h5 class="text-left font-weight-bold mb-2">Notes</h5>
                    <h6 v-if="task.decisions.length === 0" class="text-center">no notes</h6>
                    <div v-else>
                      <div v-for="(note, index) in task.decisions" :key="index" class="d-flex flex-col text-left mb-0 p-3 bg-navy text-white rounded-lg" :class="index === task.decisions.length - 1 ? 'mb-0' : 'mb-2'">
                        <p class="mb-1">
                          {{ note.reason }}
                        </p>
                        <small class="align-self-end">{{ new Date(note.date_created).toDateString() }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="singleApprovalSuccess && !errorSwitch" class="mx-4 no-saved-tasks pt-5">
        <i class="fa-regular fa-circle-check fa-5x text-success mb-3"></i>
        <h1>Task Approval response has been sent successfully</h1>
        <router-link :to="{name:'admin_tasks', params:{status: 'pending'}}">
          <button class="btn btn-primary mr-2 w-50" type="button">
            Go back to pending tasks
          </button>
        </router-link>
      </div>
      <div v-else-if="errorSwitch" class="mx-4 no-saved-tasks pt-5">
        <i class="fa-solid fa-triangle-exclamation mb-3 fa-5x text-danger"></i>
        <h1>{{ errorMessage }}</h1>
        <router-link :to="{name:'admin_tasks', params:{status: 'pending'}}">
          <button class="btn btn-primary mr-2 w-50" type="button">
            Go back to pending tasks
          </button>
        </router-link>
      </div>
      <div v-else class="mx-4 no-saved-tasks pt-5">
        <i class="fa-regular fa-face-smile fa-5x mb-3 text-success"></i>
        <h1>{{ $t('no_task_left') }}</h1>
        <p>{{ $t('no_task_left_message') }}</p>
        <b-button class="w-50" variant="primary" v-on:click="getAdminTask">{{ $t('check_new_tasks') }}</b-button>
      </div>
    </div>
    <div v-else>
      <b-spinner class="mt-5 mb-2 mb-md-4" large label="Large Spinner"/>
      <div class="mb-5">{{ $t('loading_task') }}</div>
    </div>
  </div>
</template>

<script>
import Carousel from '../../../components/Global/CardCarousel'
import APIService from '../../../apiService'
import helper from '../../../helper'
import Breadcrumb from '../../../components/Breadcrumbs/Breadcrumb'

export default {
  name: 'AdminTaskList',
  components: {
    Breadcrumb,
    Carousel
  },
  data () {
    return {
      route: window.location.toString(),
      username: localStorage.getItem('username'),
      slug: this.$route.params.slug,
      task: [],
      singleApproval: false,
      singleApprovalSuccess: false,
      LoadPage: false,
      errorSwitch: false,
      errorMessage: '',
      errorMessagesApi: [],
      decline_messages: [
        {
          'id': 'clearer_description',
          'title': 'Description needs to be clearer',
          'text': 'Buyers like detailed descriptions of exactly what you will be doing for them. Include as much detail as possible.'
        },
        {
          'id': 'clearer_product',
          'title': 'Final Product needs to be clearer',
          'text': 'You must let the buyer know what to expect as a final product. What will they get delivered? This could be a certain digital file type (such as pdf or multiple image formats or it could be the time you will spend on the task etc.)'
        },
        {
          'id': 'lack_of_description_product',
          'title': 'Lack of Information in ‘Description’ and, or ‘Final Product’',
          'text': 'Sorry, there is not enough information in your description/final product field for your task to be clear to the buyers so they can understand what you will be doing for them.  Please insert further details and resubmit your task.'
        },
        {
          'id': 'not_english',
          'title': 'Task not in English',
          'text': 'Sorry, at this time AnyTask only supports tasks written in English. We hope to bring other languages to AnyTask in the future, but in the meantime, please resubmit your task in English.'
        },
        {
          'id': 'inappropriate_content',
          'title': 'Inappropriate ‘Username’, ‘Task’ and, or ‘Task Header’',
          'text': 'Your Username/Task/Task Header is not suitable for approval and does not meet the Terms and Conditions.'
        },
        {
          'id': 'illegal',
          'title': 'Illegal Task (I.e. Drugs etc...)',
          'text': 'Your task is not suitable for approval and does not meet the Terms and Conditions of AnyTask.com.'
        },
        {
          'id': 'how_complete',
          'title': 'Lack of Explanation into ‘how’ your Task will be completed',
          'text': 'Sorry, your task does not explain how this will be completed.  Please feel free to insert more details and resubmit your task.'
        },
        {
          'id': 'physical_products',
          'title': 'Physical Products',
          'text': 'Sorry, we do not accept tasks selling physical products.'
        },
        {
          'id': 'donation_requests',
          'title': 'Donation Requests',
          'text': 'Sorry, we do not accept Donation requests, AnyTask is a digital freelancer platform.'
        },
        {
          'id': 'grammatical_errors',
          'title': 'Grammatical Errors',
          'text': 'Sorry, we are unable to accept your task based on some grammatical errors in your task. Please feel free to amend and resubmit your task.'
        },
        {
          'id': 'long_description',
          'title': 'Very long description',
          'text': 'Sorry, your task explanation/description is slightly too long and will be hard for the Buyer to read through. Please feel free to amend and resubmit your task.'
        },
        {
          'id': 'banned_tasks',
          'title': 'Money Making Schemes / Betting Schemes / No trading systems',
          'text': 'AnyTask does not accept tasks of this type, they are against the Terms and Conditions.'
        },
        {
          'id': 'selling_crypto',
          'title': 'Selling ETN or Cryptocurrencies',
          'text': 'Sorry, you cannot sell cryptocurrencies as a task. It is against the Terms and Conditions.'
        },
        {
          'id': 'inappropriate_category',
          'title': 'Inappropriate category / categories',
          'text': 'Your listing is acceptable, but you have listed it in one or more categories that are not appropriate.  Please resubmit using appropriate categories.'
        },
        {
          'id': 'other_unacceptable',
          'title': 'Other Unacceptable (unlikely to be able to resubmit)',
          'text': 'Sorry but your task is not suitable for AnyTask and is against the Terms and Conditions.'
        },
        {
          'id': 'broken_image',
          'title': 'Broken image',
          'text': 'Sorry, we can see that your image is broken on your task.  Please feel free to amend and resubmit your task.'
        },
        {
          'id': 'selling_cryptocurrencies',
          'title': 'Selling ETN or Cryptocurrencies',
          'text': 'Sorry, you cannot sell cryptocurrencies as a task.  It is against the Terms and Conditions.'
        },
        {
          'id': 'contact_details',
          'title': 'Contact Details in Description.',
          'text': 'Please do not include contact details in your task description, buyers can contact you through the AnyTask messaging system. Communicating outside of the platform will negate AnyTaskProtection™.'
        },
        {
          'id': 'social_media_likes',
          'title': 'Social media likes.',
          'text': 'Unfortunately, we are unable to list tasks guaranteeing social media likes and follows.'
        },
        {
          'id': 'remote_computer_support',
          'title': 'Remote computer support',
          'text': 'Unfortunately, we are unable to accept tasks providing remote computer support.'
        },
        {
          'id': 'inappropriate_images',
          'title': 'Inappropriate Images',
          'text': 'Kindly replace your task images;  some images are not allowed. Feel free to edit and resubmit your task.'
        },
        {
          'id': 'photo_not_compatible_with_your_description',
          'title': 'Photo Not Compatible with your Description',
          'text': 'Sorry, we can see that your task description is not compatible with your task image display. Please amend and resubmit your task.'
        },
        {
          'id': 'copyright_photo',
          'title': 'Copyright Photo',
          'text': 'Please remove or replace the web-sourced image from your task. You can make changes and resubmit. Thank you for understanding and following our requirements.'
        },
        {
          'id': 'link_in_description',
          'title': 'Link in Description',
          'text': 'Sorry, we can see that you have included a personal link in your task description. Please remove this and resubmit your task for review.'
        },
        {
          'id': 'inappropriate_photo',
          'title': 'Inappropriate Photo',
          'text': 'Sorry, we can see that one of your images is inappropriate and not compatible with the Terms and Conditions. Please feel free to amend and resubmit your task.'
        },
        {
          'id': 'contact_details_in_task_photo',
          'title': 'Contact Details in Task Photo',
          'text': 'Sorry, we can see that your Task Photo contains visible contact numbers / links details / email addresses and is not suitable for approval and does not meet our Terms & Conditions. Kindly remove the details and resubmit.'
        },
        {
          'id': 'different_prices',
          'title': 'Different Prices',
          'text': 'Sorry, we can see that you have different pricing offered from your Task description. Please create a separate Task for each package or additional cost offered.'
        },
        {
          'id': 'task_title_not_capitalized',
          'title': 'Task Title Not Capitalized',
          'text': 'Please use capital letters at the beginning of each word in your Task Title. Feel free to amend and resubmit your Task.'
        },
        {
          'id': 'promoting_different_platform_1',
          'title': 'Promoting Different Platform option 1',
          'text': 'Sorry, but we\'ve noticed that your task description appears to promote another platform, this is not acceptable. Please feel free to make the necessary adjustments and resubmit your task for review.'
        },
        {
          'id': 'promoting_different_platform_2',
          'title': 'Promoting Different Platform option 2',
          'text': 'Sorry, but we\'ve noticed that your task description appears to promote another platform, this is not acceptable. Please feel free to make the necessary adjustments and resubmit your task for review.'
        },
        {
          'id': 'image_taken_from_the_web',
          'title': 'Image Taken from the Web',
          'text': 'Sorry, we can see that your image has been taken from the web. Please feel free to amend and resubmit your task. You can make amend this and resubmit. Thank you for understanding and cooperating with our guidelines.'
        },
        {
          'id': 'header_not_suitable_to_the_description',
          'title': 'Header Not Suitable To The Description',
          'text': 'Sorry, we can see that your Task Header is not related to your task description. Please feel free to amend and resubmit your task.'
        },
        {
          'id': 'typo_in_header',
          'title': 'Typo In Header',
          'text': 'Sorry, we can see that there is an error in your Task Header. Please feel free to amend and resubmit your task.'
        }
      ]
    }
  },
  mounted () {
    this.getAdminTask()
  },
  created () {
    window.addEventListener('scroll', helper.pauseVideoOnScroll)
  },
  methods: {
    updateDeclineMessage (text) {
      document.getElementById('taskDecision' + this.task.hash).textContent = text
    },
    getImagesFromAssets (media) {
      let images = []

      media.assets.forEach(function (asset) {
        let img = {}
        img['type'] = asset.type
        img['original'] = asset.path

        if (asset.type === 2) {
          // Movie
          img['src'] = helper.formatCloudinaryUrl(asset.path, 'movie_large')
          img['mp4'] = helper.formatCloudinaryUrl(asset.path, 'movie_mp4')
        } else {
          // Image
          img['src'] = helper.formatCloudinaryUrl(asset.path, 'task_large')
        }
        images.push(img)
      })

      return images
    },
    getAdminTask () {
      this.LoadPage = false

      let taskHash = this.$route.params.hash
      if (taskHash) {
        APIService.getTaskNeedingApprovalWithHash(taskHash).then(response => {
          this.singleApproval = true
          this.task = response.data.data
          this.LoadPage = true
        }).catch((error) => {
          this.errorMessage = error.response.data.error_message
          this.errorSwitch = true
          this.task = null
          this.LoadPage = true
        })
      } else {
        window.scrollTo(0, 0)
        APIService.getTaskNeedingApproval().then(response => {
          this.task = response.data.data
          this.LoadPage = true
        }).catch(() => {
        })
      }
    },

    decision (hash, decision, value) {
      document.getElementById(value === 0 ? 'btn-decline-' + hash : 'btn-accept-' + hash).innerHTML = '<i class="fa-solid fa-circle-notch fa-spin"></i>'

      document.getElementById('taskWarning-' + hash).style.display = 'none'
      document.getElementById('taskWarningApi-' + hash).style.display = 'none'
      this.errorMessagesApi = []
      let reasonValue = document.getElementById('taskDecision' + hash).value

      if (value === 1 && reasonValue === '') {
        reasonValue = 'Ok'
      }

      if (reasonValue.length === 0) {
        document.getElementById('taskWarning-' + hash).style.display = 'block'
        document.getElementById(value === 0 ? 'btn-decline-' + hash : 'btn-accept-' + hash).innerText = decision
      } else {
        let data = {}
        if (value === 1) {
          data = {
            'decision': value,
            'reason': reasonValue
          }
        } else {
          data = {
            'decision': value,
            'reason': reasonValue
          }
        }

        APIService.adminApproveTask(hash, data).then(() => {
          document.getElementById(hash).style.display = 'none'
          if (this.singleApproval) {
            this.singleApprovalSuccess = true
            this.$router.push({ name: 'admin_tasks', params: { status: 'pending' }, hash: '#processed' })
          } else {
            this.getAdminTask()
          }
        }).catch((error) => {
          let errors = []
          if (error.response.data.code === 'HTTP-400') {
            if (error.response.data.errors.children.reason.errors) {
              document.getElementById('taskWarning-' + hash).style.display = 'block'
              errors.push(error.response.data.reason.errors)
            }
            if (error.response.data.errors.children.decision.errors) {
              document.getElementById('taskWarning-' + hash).style.display = 'block'
              errors.push(error.response.data.decision.errors)
            }
            this.errorMessagesApi = errors
            document.getElementById(value === 0 ? 'btn-decline-' + hash : 'btn-accept-' + hash).innerText = decision
          } else if (error.response.data.error_code === 'HTTP-409') {
            document.getElementById(value === 0 ? 'btn-decline-' + hash : 'btn-accept-' + hash).innerText = decision
            this.getAdminTask()
          }

          if (error.response.data.errors.children.decision.errors) {
            document.getElementById('taskWarning-' + hash).style.display = 'block'
            errors.push(error.response.data.decision.errors)
          }
          document.getElementById(value === 0 ? 'btn-decline-' + hash : 'btn-accept-' + hash).innerText = decision
        })
      }
    },
    datetime (date) {
      if (date === null) {
        return ''
      }
      const newDate = new Date(date)
      return newDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }) + ' ' + newDate.toLocaleDateString()
    }
  }
}
</script>

<style scoped>
@media (max-width: 992px) {
  .borderMobile {
    border-left-width: 0 !important;;
  }
}

p {
  font-size: 0.9rem;
}

ul li {
  list-style: none;
}

.breadcrumbs a:link,
.breadcrumbs a:visited,
.breadcrumbs a:hover,
.breadcrumbs a:active {
  text-decoration: none;
  color: #888;
}

.breadcrumbs:hover,
.breadcrumbs a {
  cursor: default;
}

.breadcrumbs a.clickable:hover {
  cursor: pointer;
  color: #000 !important;
  text-decoration: underline !important;
}

.breadcrumbs {
  z-index: 3;
  color: #888 !important;
}

.breadcrumbs i {
  color: #888;
}

.no-saved-tasks {
  color: lightslategray;
}

.dropdown-options {
  list-style: none;
}
</style>
